import { render, staticRenderFns } from "./ComponentBottom.vue?vue&type=template&id=9d4bd274&scoped=true&"
import script from "./ComponentBottom.vue?vue&type=script&lang=js&"
export * from "./ComponentBottom.vue?vue&type=script&lang=js&"
import style0 from "./ComponentBottom.vue?vue&type=style&index=0&id=9d4bd274&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d4bd274",
  null
  
)

export default component.exports