<template>
  <div>
    <a @click = "toLogin" class = "p_login" href = "javascript:void(0);">
      <img :src = "userStyle === 'black' ? userBlack : userWhite"/>
      <span :class = "userStyle === 'black' ? 'blackFont' : ''">{{username}}</span>
    </a>
    <a @click = "menuToggle" class = "p_menuBtn" :class = "menuOn ? 'on' : ''" href = "javascript:void(0);">
        <i></i>
        <i></i>
        <i></i>
    </a>
    <div v-show = "menuOn" class = "p_menuBox">
        <div class = "menuHeader">
          <a v-if = "username" @click = "logout" class = "m_btn" href = "javascript:void(0);">退出登录</a>
        </div>
        <ul v-if="navs.length > 0">
            <li v-for="(item, index) in navs" :key="index">
                <div class = "firstMenu" @click = "menuToggle">
                    <router-link :to="item.templateUrl" >
                        {{ isEn ? item.enName : item.cnName }}
                    </router-link>
                </div>
                <div @click = "menuToggle" class = "secondMenu" v-if="item.children.length > 0">
                    <router-link
                        
                        v-for="(subItem, subIndex) in item.children"
                        :key="subIndex"
                        :to="subItem.templateUrl">
                        <!--  
                        <img :src="subItem.icon" alt />
                        -->
                        <span>{{ isEn ? subItem.enName : subItem.cnName }}</span>
                    </router-link>
                </div>
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
  import {urls, staticPath} from '@/lib/common';

  export default {
    props:{
      navs:{
        type:Array
      },
      userStyle:{
        type:String
      }
    },
    data() {
      return {
        menuOn:false,
        isEn:false,
        username: '',
        userBlack: require('@/assets/images/phone/icon_user_black.png'),
        userWhite: require('@/assets/images/phone/icon_user.png')
      }
    },
    methods: {
      logout() { // 退出登录
          const url = urls.logout;
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userinfo');
          this.$http.post(url).then(res => {
              console.log(res)
              if (res.data.status !== 200) {
                  this.$message.error(res.data.message);
              } else {
                  this.$message.success("已退出登录.");
                  this.menuToggle();
                  setTimeout(() => {
                      this.$router.replace({
                          path: "/login"
                      })
                  }, 2000)
              }
          })
      },
      toLogin(){
        if(this.username){
          this.$router.push({
            path: '/userCenter'
          })
        }
        else{
          this.$router.push({
            path: '/login',
            query: {
              //editorType: type === 'login' ? 0 : 1
              editorType:0
            }
          })
        }
      },
      menuToggle(){
          this.menuOn = !this.menuOn;
          if(this.menuOn){
            document.body.style.overflow = 'hidden';
          }
          else{
            document.body.style.overflow = '';
          }
      }
    },
    watch: {
        "$i18n.locale": function(val) {
            this.isEn = val === 'en' ? true : false;
        }
    },
    destroyed () {
      //document.body.style.overflow = '';
    },
    mounted() {
      const userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
      if (userinfo) this.username = userinfo.username;
      this.$i18n.locale === 'en' ? this.isEn = true : this.isEn = false;
    }
  };
</script>

<style lang="less" scoped>
.fontFn (@px, @attr: font-size) {
    @rem: (@px / 37.5);
    @{attr}: ~"@{rem}rem";
}

.p_login{
  z-index: 2000;
  display: block;
  position: absolute;
  top:0;
  left:0;
  padding-top: 6%;
  padding-left: 4%;
  img,span{
    display: inline-block;
    vertical-align: middle;
  }
  img{
    width: 18px;
    height: auto;
    margin-right: 6px;
  }
  span{
    color:#fff;
    font-size: 14px;
    width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 20px;
    line-height: 20px;
  }
  .blackFont{
    color:#333;
  }
}

.p_menuBtn{
    z-index: 1000101;
    position: absolute;
    padding:3% 4%;
    padding-top: 6%;
    top:0;
    right:0;
    i{
        border-radius: 4px;
        display: block;
        width: 18px;
        height: 3px;
        background-color: rgba(255,255,255,0.8);
        margin-bottom: 4px;
        transition-duration: 0.3s;
    }
    &.on{position: fixed;}
    &.on i:nth-child(1){
        transform:rotate(45deg) translate(12.5%,160%);
        -webkit-transform:rotate(45deg) translate(12.5%,160%);
        -moz-transform:rotate(45deg) translate(12.5%,160%);
        -ms-transform:rotate(45deg) translate(12.5%,160%);
        -o-transform:rotate(45deg) translate(12.5%,160%);
        margin:0;
    }
    &.on i:nth-child(2){
        opacity: 0;
    }
    &.on i:nth-child(3){
        transform:rotate(-45deg) translate(12.5%,-160%);
        -webkit-transform:rotate(-45deg) translate(12.5%,-160%);
        -moz-transform:rotate(-45deg) translate(12.5%,-160%);
        -ms-transform:rotate(-45deg) translate(12.5%,-160%);
        -o-transform:rotate(-45deg) translate(12.5%,-160%);
        margin:0;
    }
}
.p_menuBox{
    overflow: auto;
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:rgba(0,0,0,0.85);
    z-index: 1000100;
    .menuHeader{
      .fontFn(60,height);
      display: flex;
      align-items: center;
      justify-content: center;
      .m_btn{
        color:#fff;
        .fontFn(16);
        padding: 2%;
      }
    }
    ul{
        
        li{
            border-top:1px solid #5d5d5d;
            a{
              color:#fff;
              display: block;
              .fontFn(16);
              .fontFn(40,height);
              .fontFn(40,line-height);
            }
            .firstMenu{
              padding-left: 4%;
              a{}
            }
            .secondMenu{
              border-top:1px solid #5d5d5d;
              padding-left: 8%;
              a{padding-left: 3%;border-bottom: 1px solid #5d5d5d}
              a:last-child{border:none;}
            }
        }
    }
}
</style>