<template>
    <div class="bottom-container">
        <div class="bottom">
            <div class="navs">
                <div class="lf">
                    <div v-for="(item, index) in navs" :key="index" class="items" v-if="item.id !== 1">
                        <h2 @click="jumpTo(item)">{{ isEn ? item.enName : item.cnName }}</h2>
                        <ul>
                            <li
                                v-for="(subItem, subIndex) in item.children"
                                :key="index + '-' + subIndex"
                                class="subItem"
                            >
                                <span @click="jumpTo(subItem)">{{ isEn ? subItem.enName : subItem.cnName }}</span>
<!--                                <router-link :to="subItem.templateUrl">{{ isEn ? subItem.enName : subItem.cnName }}</router-link>-->
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="rf">
                    <div class="logo">
                        <!--<img :src="require('@/assets/images/icon_logo.png')" alt />
                        <div>
                            <h1>邦宝益智</h1>
                            <p>股票代码:603398</p>
                        </div>-->
                        <img :src="require('@/assets/images/icon_logo.png')" alt />
                        <img :src="require('@/assets/images/logo_footer_r.png')" alt />
                    </div>
                    <div class="tel">
                        <i class="icon-tel"></i>
                        {{ $t('lang.hotline') }}：
                        <h1>4008-302-022</h1>
                    </div>
                    <div class="qrcode">
                        <div class>
                            <img :src="require('@/assets/images/qrcode1.png')" alt />
                            <p>{{ $t('lang.footer_navigation.website') }}</p>
                        </div>
                        <div class>
                            <img :src="require('@/assets/images/qrcode2.png')" alt />
                            <p>{{ $t('lang.footer_navigation.wechat') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="security-record">
            
            <p>BanBao©,BanBao packaging©,TOBEES©,TOBEES design ©, Copyright 2020 www.banbao.com All Rights Reserved, Guangdong ICP prepare No. 2021091515</p>
            <div>
                <a href = "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44051102000411" target = "_blank">
                    <img :src="iconBadge" alt />
                    <span>粤公网安备：44051102000411号</span>
                </a>
                <a href = "https://beian.miit.gov.cn/" target = "_blank">
                    <img :src="iconBadge" alt />
                    <span>粤ICP备2021091515号</span>
                </a>
            </div>
            <div class = "phone">
                <a href = "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44051102000411" target = "_blank">
                    <img :src="iconBadge" alt />
                    <span>粤公网安备：44051102000411号</span>
                </a>
                <br />
                <a href = "https://beian.miit.gov.cn/" target = "_blank">
                    <img :src="iconBadge" alt />
                    <span>粤ICP备2021091515号</span>
                </a>
            </div>

        </div>
    </div>
</template>

<script>
    const iconBadge = require("@/assets/images/icon_badge.png");
    import {urls} from '@/lib/common';
    export default {
        data() {
            return {
                iconBadge,
                navs: [],
                isEn: false,
                bottomNavs: [
                    {
                        type: 'about',
                        links: [
                            {
                                name: 'introduce',
                                url: ''
                            },
                            {
                                name: 'certificates',
                                url: ''
                            },
                            {
                                name: 'honor',
                                url: ''
                            },
                            {
                                name: 'environment',
                                url: ''
                            }
                        ]
                    },
                    {
                        type: 'product',
                        links: [
                            {
                                name: 'list',
                                url: ''
                            },
                            {
                                name: 'new',
                                url: ''
                            },
                            {
                                name: 'product_of_education',
                                url: ''
                            },
                            {
                                name: 'diy',
                                url: ''
                            },
                            {
                                name: 'download',
                                url: ''
                            }
                        ]
                    },
                    {
                        type: 'information',
                        links: [
                            {
                                name: 'news',
                                url: '/news'
                            },
                            {
                                name: 'announcement',
                                url: ''
                            },
                            {
                                name: 'investor',
                                url: '/investorRelations'
                            }
                        ]
                    },
                    {
                        type: 'education',
                        links: [
                            {
                                name: 'train_online',
                                url: ''
                            },
                            {
                                name: 'course',
                                url: ''
                            },
                            {
                                name: 'training',
                                url: ''
                            }
                        ]
                    },
                    {
                        type: 'store',
                        links: [
                            {
                                name: 'tmall',
                                url: ''
                            },
                            {
                                name: 'jd',
                                url: ''
                            },
                            {
                                name: 'amazon',
                                url: ''
                            },
                            {
                                name: 'media',
                                url: ''
                            },{
                                name: 'after_sell',
                                url: ''
                            }
                        ]
                    },
                    {
                        type: 'business',
                        links: [
                            {
                                name: 'medical',
                                url: ''
                            },
                            {
                                name: 'subsidiary',
                                url: ''
                            },
                            {
                                name: 'related_service',
                                url: ''
                            }
                        ]
                    },
                    {
                        type: 'service',
                        links: [
                            {
                                name: 'consultation',
                                url: ''
                            },
                            {
                                name: 'leave_message',
                                url: ''
                            },
                            {
                                name: 'share',
                                url: ''
                            }
                        ]
                    },
                ]
            };
        },
        watch: {
            "$i18n.locale": function(val) {
                this.isEn = val === 'en' ? true : false;
            }
        },
        methods: {
            getNavs() {
                this.$http.defaults.headers.get['Web-Site'] = 1;
                this.$http.get(urls.getTopbarNavs).then(res => {
                    if (res.data.status === 200) {
                        this.navs = res.data.data;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            jumpTo(item) {
                if (item.type === 2) {
                    window.open(item.templateUrl);
                } else {
                    this.$router.push({
                        path: item.templateUrl
                    })
                }
            }
        },
        mounted() {
            this.getNavs()
        }
    };
</script>

<style lang="less" scoped>
    .bottom {
        width: 100%;
        background: url("../assets/images/bottom_bg.png") no-repeat 100% 100%;
        background-size: 100% 100%;
        color: #fff;
        padding: 10% 0 4%;
        display: flex;
        justify-content: space-between;
        .navs {
            width: 76%;
            margin: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .lf {
                .items {
                    float: left;
                    margin-right: 3.125vw;
                    font-size: 18px;
                    h2 {
                        margin-bottom: 20px;
                        cursor: pointer;
                    }
                    .subItem {
                        line-height: 2.8vw;
                        span {
                            color: #fff;
                            font-size: 14px;
                            cursor: pointer;
                        }
                    }
                }
                &::after {
                    content: "";
                    display: block;
                    clear: both;
                }
            }
            .rf {
                .logo {
                    display: flex;
                    justify-content: space-between;
                    align-items: self-end;
                    > div {
                        h1 {
                            font-size: 2vw;
                            font-weight: normal;
                        }
                        p {
                            font-size: 1vw;
                        }
                    }

                    img:first-child {width: 60px;}
                    img:nth-of-type(2) {width: 190px; margin-left: 20px;}
                }
                .tel {
                    font-size: 1.4vw;
                    margin-top: 1.5vw;
                    color: #a4a2b0;
                    .icon-tel {
                        display: inline-block;
                        width: 1.2vw;
                        height: 1.2vw;
                        background: url("../assets/images/icon_server.png") no-repeat
                        center center;
                        background-size: 100%;
                    }
                    h1 {
                        font-size: 2vw;
                        color: #fff;
                    }
                }
                .qrcode {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-align: center;
                    margin-top: 1.5vw;
                    img {
                        width: 4.68vw;
                        height: 4.68vw;
                        margin-bottom: 0.192vw;
                    }
                }
            }
        }
    }
    .security-record {
        background: #07041c;
        text-align: center;
        font-size: 0.73vw;
        padding: 1.2vw;
        color:#fff;
        > div {
            margin-top: 0.5vw;
            a{
                color:#fff;
                &:hover{
                    text-decoration:underline;
                }
            }
            img {
                display: inline-block;
                width: 0.83vw;
                margin: 0 0.26vw 0 0.52vw;
            }
        }
        .phone{display: none}
        
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        .bottom{
            /*background-size: 1320px auto;*/
            margin-bottom: -30px;
            background-size: 350% auto;
            background-position: right 0%;
            padding: 0;
            .navs{
                 flex-direction: column-reverse;
                 padding-top:36%;
                 .lf{display: none}
                 .rf{
                    .logo{
                        margin-bottom: 20px;
                        img{
                            .fontFn(60,width);
                            .fontFn(60,height);
                            margin-right: 14px;
                        }
                        >div h1{
                            .fontFn(33);
                            position: relative;
                            top:-4px;
                            letter-spacing: 4px;
                        }
                        >div p{
                            .fontFn(17);
                            letter-spacing: 1px;
                        }
                    }
                    .tel{
                        font-family: YouSheBiaoTiHei;
                        color: #A4A2B0;
                        .fontFn(20);
                        .icon-tel{
                            .fontFn(22,width);
                            .fontFn(22,height);
                            position: relative;
                            top:4px;
                        }
                        h1{.fontFn(32);padding-top: 6%;padding-bottom: 8%}
                    }
                    .qrcode{
                        padding-bottom: 24%;
                        img{
                            .fontFn(66,width);
                            .fontFn(66,height);
                        }
                        p{
                            .fontFn(10);
                            padding-top: 12%;
                        }
                    }
                 }
            }
        }
        .security-record{
            margin-top:-2px;
            background:#2E2948;
           
            p{
                .fontFn(12);
                .fontFn(24,line-height);
                color:rgba(255,255,255,0.45)
                ;margin:0 auto;
                .fontFn(344,max-width);
            }
            >div{
                display: none;
                
            }
            .phone{
                display: block;
                padding-bottom: 4%;
                img{
                    display: inline-block;
                    vertical-align: middle;
                    .fontFn(16,width);
                    .fontFn(16,height);
                    .fontFn(6,margin-right);
                    .fontFn(8,margin-bottom);
                }
                span{
                    display: inline-block;
                    vertical-align: middle;
                    .fontFn(12);
                    .fontFn(10,margin-bottom);
                    color:rgba(255,255,255,0.45);
                }
            }

                
        }
    }
</style>