export const lang = {
  "navigation": {
    "home": "首页",
    "about_us": "关于我们",
    "about": "关于邦宝",
    "product": "产品展示",
    "news": "重大资讯",
    "education": "邦宝教育",
    "store": "邦宝商城",
    "business": "新型业务",
    "service": "客户服务",
    "login": "登录",
    "register": "注册",
    "language": "English",
    "tmall": "邦宝玩具天猫旗舰店",
    "jd": "邦宝京东官方旗舰店",
    "amazon": "亚马逊邦宝官方旗舰店",
  },
  "footer_navigation": {
    "about": "关于邦宝",
    "introduce": "公司简介",
    "certificates": "企业文化",
    "honor": "企业荣誉",
    "environment": "公司环境",

    "product": "产品展示",
    "list": "产品列表",
    "new": "热销新款",
    "product_of_education": "教育产品",
    "diy": "IP/DIY定制",
    "download": "下载中心",

    "information": "重大咨询",
    "news": "公司新闻",
    "announcement": "企业公告",
    "investor": "投资者关系",

    "education": "邦宝教育",
    "train_online": "在线培训",
    "course": "产品课程",
    "training": "培训服务",

    "store": "邦宝商城",
    "tmall": "天猫商城",
    "jd": "京东商城",
    "amazon": "亚马逊商城",
    "media": "新媒体链接",
    "after_sell": "售后服务",

    "business": "新型业务",
    "medical": "医疗器械",
    "subsidiary": "分/子公司业务",
    "related_service": "相关服务事项",

    "service": "客户服务",
    "consultation": "在线咨询",
    "leave_message": "客户留言",
    "share": "创意分享",

    "website": "官方网站",
    "wechat": "官方微信"
  },

  "more": "更多",
  "filter": "系列筛选",
  "particles": "颗粒数",
  "see_more": "查看更多",
  "enter": "立即进入",
  "hotline": "全国统一服务热线",

  "system": "制度与规范",
  "roles": "规章与规则",

  "home": "首页",
  "about": "关于邦宝",
  "main_business": "主营业务",
  "creativity": "创意展示",
  "company_exhibitions": "公司资讯",
  "product_series": "产品系列",
  "introduction": "公司简介",
  "certificates": "企业文化",
  "honor": "企业荣誉",
  "development": "发展历程",
  "strategic": "发展战略",
  "shopping": "线上购物",
  "exhibitions": "活动资讯",
  "news": "公司新闻",
  "publicity": "信息公示",
  "booklet": "图片专区",
  "video": "视频专区",
  "college": "邦宝学院",
  "journal": "内刊",
  "train": "培训",
  "activity": "员工活动",
  "communication": "交流活动",
  "investor": "投资者关系",
  "information": "基本信息",
  "announcement": "公司公告",
  "governance": "公司治理",

  'mold_introduction': '邦宝模具简介',
  'innovation_studio': '创新工作室',
  'product_display': '产品展示',
  'contact_information': '联系方式',

}