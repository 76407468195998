<template>
    <div class="home">
<!--        <img :src="require('@/assets/images/BG.jpg')" alt />-->
        <div class="content">

            <component-navbar></component-navbar>
            <div class="banner">
<!--                <div class="lf">
                    <img :src="require('@/assets/images/logo.png')" alt />
                </div>-->
                <div class="rf">
                    <v-carousel
                        :show-arrows="false"
                        hide-delimiters
                        height="auto"
                        cycle>
                        <!-- v-for="(item, i) in banners" :key="i" -->
                        <v-carousel-item
                            reverse-transition="fade-transition"
                            transition="fade-transition"
                        >

                            <!--<div class="banner-box">
                                <div class="banner-lf">
                                    <h2>{{ isEn ? item.enTitle : item.cnTitle }}</h2>
                                    <a :href="item.redirectUrl" v-if="item.redirectUrl.indexOf('http') !== -1" target="_blank">了解详情</a>
                                    <router-link :to="item.redirectUrl" v-else>了解详情</router-link>
                                </div>
                                <img :src="staticPath + (isEn ? item.enImageUrl : item.cnImageUrl)" alt class="banner-img"/>
                            </div>-->

                            <a href="https://edu.banbao.com:63398/" target="_blank">
                                <div class="_mask"></div>
                            </a>
                        </v-carousel-item>
                    </v-carousel>
                    <!--<swiper ref="mySwiper" :options="swiperOptions">
                        <swiper-slide v-for="(item, index) in banners" :key="index">
                            <div class="banner-box">
                                <div class="banner-lf">
                                    <h2>{{ isEn ? item.enTitle : item.cnTitle }}</h2>
                                    <a :href="item.redirectUrl" v-if="item.redirectUrl.indexOf('http') !== -1" target="_blank">了解详情</a>
                                    <router-link :to="item.redirectUrl" v-else>了解详情</router-link>
                                </div>
                                <img :src="staticPath + (isEn ? item.enImageUrl : item.cnImageUrl)" alt class="banner-img"/>
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                    </swiper>-->
                </div>
            </div>
<!--            <div :class="['business', 'animate__animated', 'animate__fadeInUp']" ref="business">-->
            <div class="business" :style="{'visibility': aniObj.businessVisible ? 'visible' : 'hidden'}" :class="['business', 'animate__animated', aniObj.business ? 'animate__fadeInUp' : '']" ref="business">
                <div class="box">
                    <div class="topic">
                        <span class="icon-line"></span>
                        <h2>{{ $t('lang.main_business') }}</h2>
                    </div>
                    <ul>
                        <li v-for="(item, index) in zyyw.data" :key="index" @click="goto(item.redirectUrl)">
                            <img :src="staticPath + (isEn ? item.enLogo : item.cnLogo)" alt />
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 创意展示（内容为空不显示-20231013） -->
            <div v-if="cyzs.data.length" :style="{'visibility': aniObj.videoVisible ? 'visible' : 'hidden'}" :class="['video', 'animate__animated', aniObj.video ? 'animate__fadeInUp' : '']" ref="video">
                <div class="box">
                    <div class="topic">
                        <span class="icon-line"></span>
                        <h2>{{ $t('lang.creativity') }}</h2>
                    </div>
                    <div class="video-items">
                        <button :class="['btn', index === videoIdx ? 'video-selected' : '']" v-for="(item, index) in cyzs.data" :key="index" @click="videoIdx = index">{{ isEn ? item.enTitle : item.cnTitle }}</button>
                    </div>
                    <div class="controls" v-if="cyzs.hasOwnProperty('data') && cyzs.data.length > 0">
                        <video width="100%" height="100%" controls :src="cyzs.data[videoIdx].files.length > 0 ? staticPath + cyzs.data[videoIdx].files[0].fileUrl : ''"></video>
<!--                        <video width="100%" height="100%" controls src="../assets/images/promo.mp4"></video>-->
                    </div>
                </div>
            </div>
            <!-- 公司资讯 -->
            <div :style="{'visibility': aniObj.newsVisible ? 'visible' : 'hidden'}" :class="['news', 'animate__animated', aniObj.news ? 'animate__fadeInUp' : '']" ref="news">
                <div class="box">
                    <div class="topic">
                        <span class="icon-line"></span>
                        <h2>{{ $t('lang.company_exhibitions') }}</h2>
                    </div>
                    <ul>
                        <li v-for="(item, index) in gszx.data" :key="index" v-if = "screenWidth <= 750 ? (index < 1) : (index >= 0)">
                            <div class="pic">
                                <img :src="staticPath + (isEn ? item.enLogo : item.cnLogo)" />
                            </div>
                            <h4>{{ isEn ? item.enTitle : item.cnTitle }}</h4>
                            <p>{{ isEn ? item.enContentSummary : item.cnContentSummary }}</p>
                            <i class="icon" @click="toDetail(item)"></i>
                        </li>
                    </ul>
                    <router-link :to="gszx.templateUrl" class="btn-more" v-if="gszx">{{ $t('lang.see_more') }}</router-link>
                </div>
            </div>
            <div :style="{'visibility': aniObj.productsVisible ? 'visible' : 'hidden'}" :class="['products', 'animate__animated', aniObj.products ? 'animate__fadeInUp' : '']" ref="products">
                <div class="box">
                    <div class="topic">
                        <span class="icon-line"></span>
                        <h2>{{ $t('lang.product_series') }}</h2>
                    </div>
                    <ul>
                        <li v-for="(item, index) in cpxl.data" :key="index" @click="toProductList(item.id)" v-if = "screenWidth <= 750 ? (index < 4) : (index >= 0)">
                            <el-image
                                    :src="staticPath + (isEn ? item.enLogo : item.cnLogo)"
                                    fit="contain"></el-image>
                            <!--                        <img :src="staticPath + (isEn ? item.enLogo : item.cnLogo)" />-->
                            <span>{{ isEn ? item.enName : item.cnName }}</span>
                        </li>
                    </ul>
                    <router-link :to="cpzx.templateUrl" class="btn-more" v-if="cpzx">{{ $t('lang.see_more') }}</router-link>
                </div>
            </div>
            <div :style="{'visibility': aniObj.bottombarVisible ? 'visible' : 'hidden'}" :class="['animate__animated', aniObj.bottombar ? 'animate__fadeInUp' : '']" ref="bottombar">
                <component-bottom></component-bottom>
            </div>
        </div>
    </div>
</template>

<script>

    import "swiper/dist/css/swiper.css";
    import { swiper, swiperSlide } from "vue-awesome-swiper";
    import ComponentNavbar from "@/components/ComponentNavbar.vue";
    import ComponentBottom from "@/components/ComponentBottom.vue";
    import ComponentMenuIndexH5 from "@/components/ComponentMenuIndexH5.vue";
    import {urls, staticPath, banbaoUrl} from '@/lib/common';
    import { mapActions } from 'vuex';
    import axios from 'axios'

    export default {
        name: "Home",
        components: {
            swiper,
            swiperSlide,
            ComponentNavbar,
            ComponentBottom,
            ComponentMenuIndexH5
        },
        data() {
            return {
                staticPath,
                banbaoUrl,
                videoIdx: 0,
                navs: [],
                swiperOptions: {
                    effect: "flip",
                    grabCursor: false,
                    autoplay: {
                        delay: 5000,
                        stopOnLastSlide: false,
                        disableOnInteraction: true,
                    },
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true
                    }
                },
                isEn: false,
                banners: [],
                cyzs: '',
                zyyw: '',
                gszx: '',
                cpxl: '',
                cpzx: '',
                winScrollHeight: 0,

                /********** 控制页面滚动弹出显示样式 **********/
                videoScrollTop: "",
                newsScrollTop: "",
                productsScrollTop: "",
                bottombarScrollTop: "",
                aniObj: {
                    business:document.body.clientWidth <= 750 ? true : false,
                    businessVisible:document.body.clientWidth <= 750 ? true : false,
                    video: false,
                    videoVisible: false,
                    news: false,
                    newsVisible: false,
                    products: false,
                    productsVisible: false,
                    bottombar: false,
                    bottombarVisible: false,
                },
                /********** 控制页面滚动弹出显示样式 **********/

                screenWidth: document.body.clientWidth,
                username:''
            };
        },
        computed: {
            swiper() {
                return this.$refs.mySwiper.$swiper;
            },
        },
        watch: {
            "$i18n.locale": function(val) {
                this.isEn = val === 'en' ? true : false;
            }
        },
        methods: {
            goto(link) {
                if (link.indexOf('http') !== -1) {
                    window.open(link)
                } else {
                    this.$router.push({
                        path: link
                    })
                }
            },
            toUsercenter() {
              this.$router.push({
                path: '/userCenter'
              })
            },
            toLogin() {
                console.log('login')
                this.$router.push({
                    path: '/login',
                    query: {
                        editorType: 0
                    }
                })
            },
            toRegister() {
                this.$router.push({
                    path: '/login',
                    query: {
                        editorType: 1
                    }
                })
            },
            toProductList(id) {
                if (id) {
                    this.$router.push({
                        name: 'ProductList',
                        params: {
                            parentId: encodeURIComponent(btoa(this.cpxl.id))
                        },
                        query: {
                            n: encodeURIComponent(btoa(id))
                        }
                    })
                } else {
                    this.$router.push({
                        name: 'ProductList',
                        params: {
                            parentId: encodeURIComponent(btoa(this.cpxl.id))
                        }
                    })
                }
            },
            changeLanguage() {
                window.open('https://www.banbaoglobal.com/')
                // this.changeLang();
                // this.$i18n.locale = this.$store.state.lang;
            },
            getTopbarNavs() {
                this.$http.defaults.headers.get['Web-Site'] = 1;
                this.$http.get(urls.getTopbarNavs).then(res => {
                    if (res.data.status === 200) {
                        this.navs = res.data.data;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getBannerList() { // 获取轮播
                this.$http.get(urls.getBannerList + 1).then(res => {
                    if (res.data.status === 200) {
                        this.banners = res.data.data;
                    }
                })
            },
            getTemplates() { // 获取首页栏目
                this.$http.get(urls.getTemplates + 1).then(res => {
                    if (res.data.status === 200) {
                        const result = res.data.data;
                        this.zyyw = result.zyyw;
                        this.cyzs = result.cyzs;
                        this.gszx = result.gszx;
                        this.cpxl = result.cpxl;
                        this.cpzx = result.cpzx;
                    }
                }).catch(err => {
                    console.log(err)
                })
            },

            // 监听页面滚动
            getScrollTop() {
                var scroll_top = 0;
                if (document.documentElement && document.documentElement.scrollTop) {
                    scroll_top = document.documentElement.scrollTop;
                } else if (document.body) {
                    scroll_top = document.body.scrollTop;
                }
                return scroll_top;
            },
            listenPageScrollFun() {
                document.addEventListener('scroll', this.zoomControl, true)
            },
            zoomControl() {
                var diffValue = -(document.body.clientHeight * 0.5);

                this.winScrollHeight = this.getScrollTop();
                
                if (!this.aniObj.business && this.winScrollHeight >= this.$refs.business.offsetTop + diffValue) {
                    this.aniObj.business = true;
                    this.aniObj.businessVisible = true;
                }
                if (!this.aniObj.video && this.cyzs.data.length && this.winScrollHeight >= this.$refs.video.offsetTop + diffValue) {
                    this.aniObj.video = true;
                    this.aniObj.videoVisible = true;
                }
                if (!this.aniObj.news && this.winScrollHeight >= this.$refs.news.offsetTop + diffValue) {
                    this.aniObj.news = true;
                    this.aniObj.newsVisible = true;
                }
                if (!this.aniObj.products && this.winScrollHeight >= this.$refs.products.offsetTop + diffValue) {
                    this.aniObj.products = true;
                    this.aniObj.productsVisible = true;
                }
                if (!this.aniObj.bottombar && this.winScrollHeight >= this.$refs.bottombar.offsetTop + diffValue) {
                    this.aniObj.bottombar = true;
                    this.aniObj.bottombarVisible = true;
                }
            },
            toDetail(item) {
                if (item.redirectUrl.indexOf('http') !== -1) {
                    window.open(item.redirectUrl);
                } else {
                    const articleId = encodeURIComponent(btoa(item.id));
                    const navigationId = encodeURIComponent(btoa(item.navigationId));
                    this.$router.push({
                        name: 'NewsDetail',
                        params: {
                            id: articleId
                        },
                        query: {
                            n: navigationId
                        }
                    })
                }
            },
            ...mapActions([
                'changeLang'
            ])
        },

        beforeDestroy() {
            document.removeEventListener('scroll', this.zoomControl, true)
        },
        created() {
            const userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
            // if (userinfo) this.username = userinfo.username;
            // const nickname = this.$cookies.get('app.nickname', {domain: banbaoUrl, path: ''});
            if (userinfo) {
                this.username = userinfo.realName;
            }
        },
        mounted() {
            const self = this;
            const token = self.$cookies.get('app.token', {domain: banbaoUrl, path: ''});
            if (token) {
                self.token = token
            }
            self.listenPageScrollFun()


            axios.all([self.getTopbarNavs(), self.getTemplates(), self.getBannerList()]).then(
                axios.spread(function(res1, res2, res3){
                    console.log('所有请求完成', res1, res2, res3)
                    self.videoScrollTop = self.$refs.video.offsetTop;
                    self.newsScrollTop = self.$refs.news.offsetTop;
                    self.productsScrollTop = self.$refs.products.offsetTop;
                    self.bottombarScrollTop = self.$refs.bottombar.offsetTop;
            }))

            const that = this;
            window.onresize = () => {
                return (() => {
                    window.screenWidth = document.body.clientWidth;
                    that.screenWidth = window.screenWidth;
                    //console.log(that.screenWidth);
                })()
            }
        }
    };

</script>

<style lang="less" scoped>
    @deep: ~'>>>';

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    .home {
        width: 100%;
        height: 100%;
        position: relative;
        &::after {
            content: '';
            display: block;
            clear: both;
        }
        > img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            vertical-align: bottom;
        }
        .content {
            /*position: absolute;*/
            z-index: 999;
            width: 100%;
            .phoneHead{display: none}
            #nav {
                position: absolute;
                top: 0;
                left: 300px;
                z-index: 999;
                min-width: 1024px;
                width: 60%;
                margin: auto;
                padding: 30px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .navs {
                    /*width: 75%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 0.95vw;*/
                    width: 75%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 16px;
                    font-weight: bold;
                    li {
                        position: relative;
                        &.arrowDown {
                            &::after {
                                content: '';
                                position: absolute;
                                left: 50%;
                                bottom: 0;
                                margin-left: -2.5px;
                                display: inline-block;
                                width: 5px;
                                height: 5px;
                                border: 1px solid #fff;
                                border-top: 0;
                                border-right: 0;
                                transform: rotate(-45deg);
                            }
                        }
                        > a {
                            color: #ffffff;
                            position: relative;
                            &::after {
                                content: "";
                                display: block;
                                margin: 5px auto 0;
                                width: 16px;
                                height: 3px;
                                background: transparent;
                                border-radius: 3px;
                            }

                            &.router-link-exact-active::after {
                                background: #fff;
                            }
                        }
                        &:hover {
                            .subNavs {
                                display: block;
                            }
                        }
                    }

                    .subNavs {
                        /*position: absolute;
                        left: 50%;
                        top: 25px;
                        margin-left: -6vw;
                        font-size: 14px;
                        width: 12vw;
                        padding: 1vw;
                        background: rgba(0, 0, 0, 0.2);
                        border-radius: 6px;
                        text-align: center;
                        display: none;*/
                        position: absolute;
                        left: 50%;
                        top: 30px;
                        margin-left: -3vw;
                        font-size: 15px;
                        line-height: 20px;
                        width: 8vw;
                        padding: 1vw;
                        background: rgba(0,0,0,.4);
                        border-radius: 6px;
                        text-align: center;
                        display: none;
                        a {
                            display: flex;
                            color: #ffffff;
                            margin-bottom: 10px;
                            font-size: 0.73vw;
                            img {
                                width: 16px;
                                height: 16px;
                                float: left;
                            }
                            span {
                                margin-left: 5px;
                                font-size: 0.73vw;
                            }
                            &::after {
                                content: "";
                                display: block;
                                clear: both;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                .nav-other {
                    width: 22%;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    margin-left: 3%;
                    > div {
                        cursor: pointer;
                        color: #fff;
                        margin-bottom: 5px;
                    }
                    .login {
                        border: 1px solid #ddd;
                        border-radius: 5px;
                        padding: 0 20px;
                        margin-bottom: 5px;
                        line-height: 28px;
                        button {
                            background: none;
                            color: #fff;
                            border: 0;
                            outline: none;
                            font-family: Avenir, Helvetica, Arial, sans-serif;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                        }
                    }
                    .register {
                        a {
                            font-size: 14px;
                            color: #fff;
                        }
                    }
                }
            }
            .banner {
                padding-top: 100px;
                background: url("../assets/images/BG_01.jpg") no-repeat center center;
                background-size: 100% 100%;
                width: 1920px;
                height: 992px;
                overflow: hidden;
                .lf {
                    float: left;
                    width: 20%;
                    box-sizing: border-box;
                    > img {
                        width: 197px;
                        margin: 60px 80px;
                    }
                    .banner-img {
                        height: 38vw;
                    }
                    &:last-child {
                        width: 80%;
                        box-sizing: border-box;
                    }
                    @{deep} .swiper-pagination-bullet-active {
                        width: 2vw;
                        height: 0.8vw;
                        border-radius: 2vw;
                    }
                }
                .rf {
                    float: right;
                    width: 100%;
                    box-sizing: border-box;
                    @{deep} .v-carousel {
                        .v-image__image--cover {
                            background-size: contain;
                        }
                        .v-carousel__controls {
                            background-color: transparent;
                            padding-left: 30%;
                            justify-content: flex-start;
                        }
                        ._mask {
                            width: 100%;
                            height: 600px;
                            //background-color: #E6A23C;
                        }
                    }
                    @{deep} .swiper-slide,
                    @{deep} .v-carousel {
                        .banner-box {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding-bottom: 10vw;
                            .banner-lf {
                                color: #fff;
                                margin-bottom: 40px;
                                font-size: 50px;
                                text-align: left;
                                box-sizing: border-box;
                                padding-left: 90px;
                                a {
                                    color: #fff;
                                    display: inline-block;
                                    padding: 8px 20px;
                                    border-radius: 50px;
                                    background: linear-gradient(to bottom,  #F7B527, #FD8E39);
                                    position: relative;
                                    overflow: hidden;
                                    font-size: 1vw;
                                    margin-top: 2vw;
                                    &::before {
                                        content: '';
                                        display: inline-block;
                                        position: absolute;
                                        left: -0.5vw;
                                        top: -0.8vw;
                                        width: 2vw;
                                        height: 2vw;
                                        border-radius: 50%;
                                        background-color: #FCCF7C;
                                    }
                                    &::after {
                                        content: '';
                                        display: inline-block;
                                        position: absolute;
                                        right: -0.5vw;
                                        bottom: -0.8vw;
                                        width: 2vw;
                                        height: 2vw;
                                        border-radius: 50%;
                                        background-color: rgba(255, 226, 175, 0.5);
                                    }
                                }
                            }
                            >img {
                                    /* width: 60%; */
                                    margin-right: 15%;
                                    position: relative;
                                    top: 154px;
                                    width: 910px;
                            }
                        }
                        &::after {
                            content: '';
                            display: block;
                            clear: both;
                        }
                    }
                }
                &::after {
                    content: "";
                    display: block;
                    clear: both;
                }
            }
            .business,
            .video {
                background: url("../assets/images/BG_02.jpg") no-repeat center center;
                background-size: 100% 100%;
                >.box {
                    width: 1180px;
                    margin: 0 auto;
                    padding: 2vw 0 5vw;
                }
                &.video {
                    background-image: url("../assets/images/BG_03.jpg");
                    >.box {
                        padding-bottom: 15vw;
                    }
                }
                .topic {
                    margin: 0 0 40px 3.125vw;
                    h2 {
                        position: relative;
                        font-size: 28px;
                        margin-top: 5px;
                        &::before {
                            content: "";
                            display: block;
                            top: 4px;
                            width: 4em;
                            border-bottom: 1px solid #ff4b7c;
                            margin-bottom: 10px;
                        }
                        &::after {
                            content: "";
                            display: block;
                            position: absolute;
                            left: 0;
                            top: -4px;
                            width: 34px;
                            height: 9px;
                            background: #ff4b7c;
                            border-radius: 10px;
                        }
                    }
                }
                ul {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    li {
                        width: 580px;
                        cursor: pointer;
                    }
                }
                .video-items {
                    text-align: right;
                    margin: 1vw auto;
                    .btn {
                        font-size: 18px;
                        height: 28px;
                        line-height:28px;
                        color: #A3A1B2;
                        padding-left: 0.5vw;
                        margin-left: 0.5vw;
                        &.video-selected {
                            transition: all .3s linear;
                            color: #FF4B7C;
                            position: relative;
                            font-weight: bold;
                            &::before {
                                content: '';
                                display: inline-block;
                                border: 8px solid transparent;
                                border-left-color: #FF4B7C;
                                position: absolute;
                                top: 6px;
                                left: -1px;
                                border-radius: 3px;
                            }
                        }
                    }
                }
                .controls {
                    margin-left: 3.125vw;
                    box-shadow: 0 10px 40px #3A345F;
                    /* height: 36vw; */
                    background-color: #3A345F;
                    video {
                        vertical-align: bottom;
                    }
                }
            }
            .news{
                background: url("../assets/images/BG_04.jpg") no-repeat top center;
                // background-size: 100% 100%;
                >.box {
                    width: 1180px;
                    margin: 0 auto;
                    padding-top: 3vw;
                }
                .topic {
                    text-align: center;
                    font-size: 28px;
                    margin-bottom: 8%;
                    .icon-line {
                        display: block;
                        width: 34px;
                        height: 9px;
                        background: #ff4b7c;
                        border-radius: 10px;
                        margin: 20px auto 15px;
                    }
                }
                ul {
                    display: flex;
                    justify-content: space-between;
                    li {
                        width: 320px;
                        background: #fff;
                        margin: 0 30px;
                        line-height: 24px;
                        box-shadow: 0 10px 40px rgba(0, 181, 255, 0.08);
                        .pic {
                            position: relative;
                            img {
                                width: 320px;
                                height: 220px;
                            }
                            &::after {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 40%;
                                width: 100%;
                                height: 100%;
                                background: url("../assets/images/article_bg.png") no-repeat center center;
                                background-size: 100%;
                            }
                        }
                        h4 {
                            /*color: #6b6667;
                            padding: 5px 15px;
                            display: -webkit-box;
                            overflow: hidden;
                            font-size: 14px;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;*/
                            color: #6b6667;
                            padding: 18px;
                            display: -webkit-box;
                            overflow: hidden;
                            font-size: 16px;
                            line-height: 24px;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            height: 88px;
                        }
                        >p {
                            /*height: 88px;
                            margin: 5px 15px 0;
                            font-size: 12px;
                            line-height: 22px;
                            color: #aaaaaa;
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-line-clamp: 4;
                            -webkit-box-orient: vertical;*/
                            margin: 5px 15px 0;
                            font-size: 12px;
                            line-height: 25px;
                            color: #aaa;
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-line-clamp: 4;
                            -webkit-box-orient: vertical;
                        }
                        >span {
                            display: inline-block;
                            width: 100%;
                            margin: 10px auto;
                            text-align: center;
                            font-size: 1vw;
                            font-weight: bold;
                        }
                        .icon {
                            display: inline-block;
                            width: 28px;
                            height: 18px;
                            background: url("../assets/images/icon_arrow.png") no-repeat 100% 100%;
                            background-size: contain;
                            cursor: pointer;
                            margin: 10px 20px 20px;
                        }
                    }
                }
                .btn-more {
                    /*display: block;
                    width: 11.45vw;
                    height: 3.64vw;
                    line-height: 3.64vw;
                    margin: 10% auto 0;
                    font-size: 1.1vw;
                    border-radius: 50px;
                    text-align: center;
                    background: linear-gradient(to left top, #dc000c, #ff4b7c);
                    color: #fff;
                    box-sizing: border-box;*/
                    display: block;
                    width: 220px;
                    height: 70px;
                    line-height: 70px;
                    margin: 60px auto;
                    font-size: 20px;
                    /* font-weight: 600; */
                    border-radius: 90px;
                    text-align: center;
                    background: linear-gradient(to left top, #dc000c, #ff4b7c);
                    color: #fff;
                    box-sizing: border-box;
                    box-shadow: 0 20px 30px rgba(247, 144, 170, 0.49);
                }
            }
            .products {
                background: url("../assets/images/BG_04.jpg") no-repeat center center;
                background-size: 100% 100%;
                >.box {
                    width: 1300px;
                    margin: 0 auto;
                    padding-top: 3vw;
                }
                .topic {
                    text-align: center;
                    font-size: 28px;
                    margin-bottom: 8%;
                    .icon-line {
                        display: block;
                        width: 34px;
                        height: 9px;
                        background: #ff4b7c;
                        border-radius: 10px;
                        margin: 20px auto 15px;
                    }
                }
                ul {
                    display: flex;
                    /*justify-content: space-between;*/
                    align-items: center;
                    li {
                        margin: 15px 15px 30px;
                        border-radius: 10px 10px 0 0;
                        .pic {
                            position: relative;
                            @{deep} .el-image {
                                img {
                                    width: 100%;
                                    height: 14.5vw;
                                }
                            }
                            &::after {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 38%;
                                width: 100%;
                                height: 100%;
                                background: url("../assets/images/article_bg.png") no-repeat center center;
                                background-size: 100%;
                            }
                        }
                        h4 {
                            /*color: #6b6667;
                            padding: 5px 15px;
                            display: -webkit-box;
                            overflow: hidden;
                            font-size: 14px;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;*/
                            color: #6b6667;
                            padding: 18px;
                            display: -webkit-box;
                            overflow: hidden;
                            font-size: 16px;
                            line-height: 24px;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            height: 88px;
                        }
                        >p {
                            /*height: 88px;
                            margin: 5px 15px 0;
                            font-size: 12px;
                            line-height: 22px;
                            color: #aaaaaa;
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-line-clamp: 4;
                            -webkit-box-orient: vertical;*/
                            margin: 5px 15px 0;
                            font-size: 14px;
                            line-height: 25px;
                            color: #aaa;
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-line-clamp: 4;
                            -webkit-box-orient: vertical;
                        }
                        >span {
                            display: inline-block;
                            width: 100%;
                            margin: 0 auto 20px;
                            text-align: center;
                            font-size: 18px;
                        }
                        .icon {
                            display: inline-block;
                            width: 1.45vw;
                            height: 1.07vw;
                            background: url("../assets/images/icon_arrow.png") no-repeat 100% 100%;
                            background-size: contain;
                            cursor: pointer;
                            margin: 10px 20px 20px;
                        }
                    }
                }
                .btn-more {
                    /*display: block;
                    width: 11.45vw;
                    height: 3.64vw;
                    line-height: 3.64vw;
                    margin: 10% auto 0;
                    font-size: 1.1vw;
                    border-radius: 50px;
                    text-align: center;
                    background: linear-gradient(to left top, #dc000c, #ff4b7c);
                    color: #fff;
                    box-sizing: border-box;*/
                    display: block;
                    width: 220px;
                    height: 70px;
                    line-height: 70px;
                    margin: 60px auto;
                    font-size: 20px;
                    border-radius: 50px;
                    text-align: center;
                    background: linear-gradient(to left top,#dc000c,#ff4b7c);
                    color: #fff;
                    box-sizing: border-box;
                    box-shadow: 0 20px 30px rgba(247, 144, 170, 0.49);
                }
            }
            .news {
                .topic {
                    color: #fff;
                    .icon-line {
                        background-color: rgba(255,255,255,.5);
                    }
                }
                li {
                    img {
                        height: 10vw;

                    }
                }
            }
            .products {
                /*padding-top: 6vw;*/
                &.news {
                    margin-top: 10vw;
                }
                background-image: url("../assets/images/BG_05.jpg");
                ul {
                    flex-wrap: wrap;
                    li {
                        width: 280px;
                        height: 330px;
                        cursor: pointer;
                        margin-bottom: 4%;
                        box-shadow: 0 10px 40px rgba(40, 40, 40, 0.15);
                    }
                }
                .btn-more {
                    margin-bottom: 10%;
                }
            }
        }
    }
    .el-image {height: 280px;}
    .topBtn{display: none}

    @media screen and (max-width: 750px) {
        .home{
            .content{
                .phoneHead{
                    display: block;
                    position: relative;
                    .p_bg{display: block;width: 100%;}
                    .p_main{
                        position: absolute;
                        top:4%;
                        width: 100%;
                        z-index: 1000;
                        .p_logo{
                            width: 26.9%;
                            margin:0 auto;
                            img{
                                display: block;
                                width: 100%;
                            }
                        }
                        .p_lx{
                            padding-top: 10%;
                            .banner-box{
                                text-align: right;
                                position: relative;
                                .banner-lf{
                                    position: absolute;
                                    text-align: left;
                                    width: 100%;
                                    h2{color:#fff;.fontFn(24);width: 38.4%;padding-bottom: 3.2%;padding-left: 2.6%}
                                    a{
                                        display: block;
                                        width:12.5%;
                                        padding-left: 2.6%;
                                        img{display: block;width: 100%}
                                    }
                                }
                                .banner-img{
                                    width: 67%
                                }
                            }
                        }
                    }
                }

                #nav{display: none}
                .banner{display: none}
                .business,
                .video{
                    >.box{width: 100%;position: relative;}
                    .topic{
                        position: relative;
                        margin: 0;
                        padding-left: 2.6%;
                        padding-bottom: 3%;
                        h2{
                            .fontFn(18);
                            &::before{
                                width: 20%
                            }
                            &::after{
                                height: 5px;
                                top:-2px;
                                width: 5%;
                            }
                        }
                    }
                    ul{
                        li{
                            width: 95%;
                            margin:0 auto;
                        }
                    }
                    .controls{
                        margin:0 5.2%;
                    }
                    .video-items{
                        position: absolute;
                        top:5.6%;
                        right: 5%;
                        width: 70%;
                        .btn{

                            .fontFn(16);
                            &.video-selected{
                                margin-left: 5%;
                                &::before{
                                    left:-8px;
                                }
                            }
                        }
                    }
                }
                .news{
                    background-image: url('../assets/images/BG_04.jpg');
                    background-size: 170% auto;
                    >.box{width: 100%}
                    .topic{
                        .icon-line{
                            width: 5.4%;
                            height: 6px;
                            margin-top:3.3%;
                            margin-bottom: 2%;
                        }
                        h2{
                            .fontFn(18);
                        }
                    }
                    ul{
                        padding: 0 7.5%;
                        display: block;
                        li{
                            margin:0;
                            width: 100%;
                            .pic{
                                width: 100%;
                                img{width:100%;height: auto}
                            }
                            h4{
                                .fontFn(16);
                                padding:30px;
                            }
                            >p{
                                .fontFn(14);
                                margin:0;
                                padding: 0 30px;
                            }
                            .icon{
                                width: 8.43%;
                                height: 0;
                                padding-top: 10%;
                                margin:20px 30px 30px;
                            }
                        }
                    }
                    .btn-more{
                        width: 37.33%;
                        height: 44px;
                        line-height: 44px;
                        margin-top:20px;
                        margin-bottom:60px;
                        .fontFn(12);
                        font-weight: 500;
                    }
                }
                .products{
                    background-size: 170% auto;
                    background-position:center top;
                    >.box{width: 100%}
                    .topic{
                        .icon-line{
                            width: 5.4%;
                            height: 6px;
                            margin-top:1.4%;
                            margin-bottom: 2%;
                        }
                        h2{
                            .fontFn(18);
                        }
                    }
                    ul{
                        display: block;
                        text-align:center;
                        li{
                            width: 45.3%;
                            margin:0 2% 20px;
                            display: inline-block;
                            vertical-align: top;
                            height: auto;
                            >span{
                                .fontFn(12);
                                color:#333;
                                font-weight: normal;
                            }
                        }
                    }
                    .btn-more{
                        width: 37.33%;
                        height: 44px;
                        line-height: 44px;
                        margin-top:20px;
                        margin-bottom:30px;
                        .fontFn(12);
                        font-weight: 500;
                    }
                }
            }
        }
        .el-image {height: auto;}

        
    }   
</style>