export const lang = {
  "navigation": {
    "home": "Home",
    "about_us": "About",
    "about": "About",
    "product": "Product",
    "news": "News",
    "education": "Education",
    "store": "Store",
    "business": "Business",
    "service": "Service",
    "login": "Login",
    "register": "Register",
    "language": "Chinese",
    "tmall": "Tmall Store",
    "jd": "JD Store",
    "amazon": "Amazon Store",
  },
  "footer_navigation": {
    "about": "About",
    "introduce": "Introduce",
    "certificates": "Certificates",
    "honor": "Honor",
    "environment": "Environment",

    "product": "Product",
    "list": "Products",
    "new": "The New",
    "product_of_education": "Educations",
    "diy": "DIY",
    "download": "Download",

    "information": "Infotmation",
    "news": "News",
    "announcement": "Announcement",
    "investor": "Investor",

    "education": "Education",
    "train_online": "Train Online",
    "course": "Course",
    "training": "Training",

    "store": "Store",
    "tmall": "Tmall Store",
    "jd": "JD Store",
    "amazon": "Amazon",
    "media": "Media",
    "after_sell": "After Sell",

    "business": "Business",
    "medical": "Medical",
    "subsidiary": "Subsidiary",
    "related_service": "Related Service",

    "service": "Service",
    "consultation": "Consultation",
    "leave_message": "Leave Message",
    "share": "Share",

    "website": "Website",
    "wechat": "WeChat"
  },

  "more": "more",
  "filter": "Filter",
  "particles": "Particles",
  "see_more": "see more",
  "enter": "Enter",
  "hotline": "Hot Line",

  "system": "System",
  "roles": "Roles",

  "home": "Home",
  "about": "About",
  "main_business": "Business",
  "creativity": "Creativity",
  "company_exhibitions": "Company",
  "product_series": "Product Series",
  "introduction": "Introduction",
  "certificates": "Certificates",
  "honor": "Honor",
  "development": "Development",
  "strategic": "Strategic",
  "shopping": "Shopping",
  "exhibitions": "Exhibitions",
  "news": "News",
  "publicity": "Publicity",
  "booklet": "Booklet",
  "video": "Video",
  "college": "College",
  "journal": "Journal",
  "train": "Train",
  "activity": "Activity",
  "investor": "Investor",
  "information": "Information",
  "communication": "Communication",
  "announcement": "Announcement",
  "governance": "Governance"
}