<template>
    <div class="navBg">
        <div class="navbar">
            <div class="logo">
                <img src="../assets/images/nav_logo.png" alt/>
            </div>
            <ul class="navs" v-if="navs.length > 0">
                <li
                        v-for="(item, index) in navs"
                        :key="index"
                >
                    <router-link :to="item.templateUrl" :exact="item.templateUrl === '/' ? true : false">
                        {{ isEn ? item.enName : item.cnName }}
                        <!--<div class="subNavs" v-if="navs.length > 0 && item.children.length > 0">
                            <router-link
                                    v-for="(subItem, subIndex) in item.children"
                                    :key="subIndex"
                                    :to="subItem.templateUrl"
                            >
                                &lt;!&ndash;              <img :src="subItem.icon" alt />&ndash;&gt;
                                <span>{{ isEn ? subItem.enName : subItem.cnName }}</span>
                            </router-link>
                        </div>-->
                    </router-link>
                </li>
            </ul>
            <div class="nav-other">
                <button class="user" @click="toUsercenter" v-if="username">{{ username }}</button>
                <button class="logout" @click="logout" v-if="username">退出</button>
                <button class="login" @click="toLogin('login')" v-if="!username">{{$t('lang.navigation.login')}}
                </button>
                <button class="register" @click="toLogin('register')" v-if="!username">
                    {{$t('lang.navigation.register')}}
                </button>
                <button class="lang" @click="changeLanguage">{{$t('lang.navigation.language')}}</button>
            </div>
        </div>
        
        <div class="phoneHeader">
            <ComponentMenuIndexH5 :navs="navs"/>
            <div class="p_logo">
                <img src="../assets/images/nav_logo.png" alt/>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from 'vuex';
    import ComponentMenuIndexH5 from "@/components/ComponentMenuIndexH5.vue";
    import {urls, banbaoUrl} from '@/lib/common'

    export default {
        components: {
            ComponentMenuIndexH5
        },
        data() {
            return {
                banbaoUrl,
                navs: [],
                username: '',
                isEn: false
            }
        },
        methods: {
            changeLanguage() {
                window.open('https://www.banbaoglobal.com/')
                /*this.changeLang();
                this.$i18n.locale = this.$store.state.lang;
                if (this.$store.state.lang === 'en') {
                  this.isEn = true;
                } else {
                  this.isEn = false;
                }*/
            },
            toLogin(type) {
                this.$router.push({
                    path: '/login',
                    query: {
                        editorType: type === 'login' ? 0 : 1
                    }
                })
            },
            toUsercenter() {
                this.$router.push({
                    path: '/userCenter'
                })
            },
            getTopbarNavs() {
                this.$http.defaults.headers.get['Web-Site'] = 1;
                this.$http.get(urls.getTopbarNavs).then(res => {
                    if (res.data.status === 200) {
                        this.navs = res.data.data;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            logout() { // 退出登录
                const url = urls.logout;
                this.$cookies.set('app.platform', 'website', {domain: banbaoUrl, expires: 1, path: ''});

                this.$cookies.remove('app.token', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.username', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.nickname', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.email', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.uid', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.isTeacher', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.roles', {domain: banbaoUrl, path: ''});
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('userinfo');
                this.$http.post(url).then(res => {
                    console.log(res)
                    if (res.data.status !== 200) {
                        this.$message.error(res.data.message);
                    } else {
                        this.$message.success("已退出登录.");
                        setTimeout(() => {
                            this.$router.replace({
                                path: "/login"
                            })
                        }, 2000)
                    }
                })
            },
            ...mapActions([
                'changeLang'
            ])
        },
        created() {
            /*const userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
            if (userinfo) this.username = userinfo.username;*/
            const nickname = this.$cookies.get('app.nickname', {domain: banbaoUrl, path: ''});
            if (nickname) {
                this.username = nickname;
            }
            this.$i18n.locale === 'en' ? this.isEn = true : this.isEn = false;
            this.getTopbarNavs();
        }
    };
</script>

<style lang="less" scoped>
    .navBg {
        background: #d93c45;
    }
    
    .navbar {
        width: 1500px;
        background: #d93c45;
        margin: 0 auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        z-index: 9999;
        /*position:absolute;*/
        /*left: 0;*/
        /*top: 0;*/
        height: 110px;
        
        .logo {
            margin-right: 44px;
            width: 226px;
            flex: none;
        }
        
        .navs {
            flex-grow: 1;
            flex: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            box-sizing: border-box;
            
            li {
                > a {
                    display: inline-block;
                    color: #ffffff;
                    line-height: 40px;
                    position: relative;
                    font-weight: bold;
                    
                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        margin: 5px 0 0 -8px;
                        width: 16px;
                        height: 3px;
                        background: transparent;
                        border-radius: 3px;
                    }
                    
                    &.router-link-active::after {
                        background: #fff;
                    }
                }
                
                &:hover {
                    .subNavs {
                        display: block;
                    }
                }
            }
            
            .subArrow {
                position: relative;
            }
            
            .subArrow::after {
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                position: absolute;
                left: 50%;
                top: 1.8em;
                margin-left: -4px;
                border: 1px solid #fff;
                border-top: 0;
                border-radius: 2px;
                border-right: 0;
                transform: rotate(-45deg);
            }
            
            .subNavs {
                position: absolute;
                left: 50%;
                margin-left: -3vw;
                top: 42px;
                font-size: 15px;
                width: 7vw;
                padding: 1vw;
                background: rgba(0, 0, 0, 0.4);
                border-radius: 6px;
                text-align: left;
                display: none;
                line-height: normal;
                z-index: 333;
                
                a {
                    display: block;
                    color: #ffffff;
                    font-size: 12px;
                    margin-bottom: 10px;
                    
                    img {
                        width: 16px;
                        height: 16px;
                        float: left;
                    }
                    
                    span {
                        margin-left: 5px;
                    }
                    
                    &::after {
                        content: "";
                        display: block;
                        clear: both;
                    }
                    
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        
        .nav-other {
            flex: none;
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-left: 40px;
            font-size: 14px;
            /*position: relative;*/
            /*top: -0.15vw;*/
            
            > button {
                padding: 0 26px;
                box-sizing: border-box;
                line-height: 36px;
                cursor: pointer;
                color: #fff;
                background: none;
                border: 0;
                outline: none;
                font-family: Avenir, Helvetica, Arial, sans-serif;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
            
            .login {
                border: 1px solid #ddd;
                border-radius: 5px;
                padding: 0 1.4vw;
            }
        }
    }
    
    .phoneHeader {
        display: none
    }
    
    
    @deep: ~'>>>';
    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
            @{attr}: ~"@{rem}rem";
    }
    
    @media screen and (max-width: 750px) {
        .navbar {
            display: none;
        }
        
        .phoneHeader {
            display: block;
            background: #D93C45;
            height: 70px;
            
            @{deep} .p_login {
                padding-top: 22px;
                padding-left: 12px;
            }
            
            @{deep} .p_menuBtn {
                padding: 24px 16px;
            }
            
            .p_logo {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 70px;
                
                img {
                    width: 132px;
                }
            }
        }
    }
</style>