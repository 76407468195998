import axios from 'axios';
import router from '../router';
import { Message } from 'element-ui';

import {banbaoUrl} from '../lib/common';
import Cookies from 'js-cookie';

// const baseURL = 'http://192.168.10.33:18000/';
axios.defaults.baseURL = '/api'
// axios.defaults.baseURL = 'http://192.168.10.33:18000/'
axios.defaults.headers.post['Content-Type'] = 'application/json'

const service = axios.create({
    // baseURL,
    timeout: 60000
})

const loginOvertimeHandle = function() {
    let info = {
        pathName: router.history.current.name,
        params: router.history.current.params
    }

    if (router.history.current.query && router.history.current.query.n) {
        info.query = {
            n: router.history.current.query.n
        };
    }
    Cookies.set('app.platform', 'website', {domain: banbaoUrl, expires: 1, path: ''});

    Cookies.remove('app.token', {domain: banbaoUrl, path: ''});
    Cookies.remove('app.username', {domain: banbaoUrl, path: ''});
    Cookies.remove('app.nickname', {domain: banbaoUrl, path: ''});
    Cookies.remove('app.email', {domain: banbaoUrl, path: ''});
    Cookies.remove('app.uid', {domain: banbaoUrl, path: ''});
    Cookies.remove('app.isTeacher', {domain: banbaoUrl, path: ''});
    // Cookies.remove('app.roles', {domain: banbaoUrl, path: ''});

    sessionStorage.setItem('prevPath', JSON.stringify(info))
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('userinfo')
    sessionStorage.removeItem('roles')
    setTimeout(() => {
        window.location.href='/login?editorType=0';
    }, 2000)
}

service.interceptors.request.use(
    config => {
        const token = Cookies.get('app.token', {domain: banbaoUrl, path: ''}) || sessionStorage.getItem('token');
        if (token) {
            config.headers.authorization = token
            config.headers.token = token
        }
        return config
    },
    err => {
        return Promise.reject(err)
    }
)

service.interceptors.response.use(
    response => {
        const res = response.data;
        switch(res.status) {
            case 401:
                Message({
                    message: '登陆信息已过期,请重新登陆!',
                    type: 'error',
                    duration: 3 * 1000
                })
                loginOvertimeHandle()
                break;
            case 106:
                Message({
                    message: res.message,
                    type: 'error',
                    duration: 3 * 1000
                })
                loginOvertimeHandle()
                break;
            default:
                break;
        }
        return response
    },
    error => {
        return Promise.reject(error.response)
    }
)

export default service
