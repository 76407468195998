import Vue from 'vue'
import VueRouter from 'vue-router'
import http from '../lib/http';

Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {content: {}},
        component: () => import('../views/Home.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/c',
        component: () => import('../views/Content.vue'),
        children: [
            {
                path: 'about',
                name: 'About',
                component: () => import('../views/about/About.vue')
            },
            {
                path: 'about/:page',
                name: 'AboutSubpage',
                meta: {keepAlive: true},
                component: () => import('../views/about/subpage/Subpage.vue')
            },
            {
                path: 'product/:navigationId',
                name: 'Product',
                component: () => import('../views/product/Products.vue')
            },
            {
                path: 'productList/:parentId',
                name: 'ProductList',
                component: () => import('../views/product/ProductList.vue')
            },
            {
                path: 'product/recourseDownload/:navigationId',
                name: 'RecourseDownload',
                component: () => import('../views/product/RecourseDownload.vue')
            },
            {
                path: 'product/mold/:navigationId',
                name: 'Mold',
                component: () => import('../views/product/Mold.vue')
            },
            {
                path: 'news',
                name: 'News',
                component: () => import('../views/news/Index.vue'),
                children: [
                    {
                        path: '',
                        component: () => import('../views/news/News.vue')
                    },
                    {
                        path: 'newsList/:page?',
                        meta: {keepAlive: true},
                        component: () => import('../views/news/NewsList.vue')
                    }
                ]
            },
            {
                path: 'newsDetail/:id',
                name: 'NewsDetail',
                component: () => import('../views/news/NewsDetail.vue')
            },
            {
                path: 'investorRelations',
                name: 'InvestorRelations',
                component: () => import('../views/investor/index.vue'),
                children: [
                    {
                        path: '/',
                        component: () => import('../views/investor/InvestorRelations.vue')
                    }, {
                        path: 'subpage/:page',
                        name: 'InvestorRelationsSubpage',
                        meta: {keepAlive: true},
                        component: () => import('../views/investor/Subpage.vue')
                    }
                ]
            },
            {
                path: 'customer',
                name: 'Customer',
                component: () => import('../views/customer-service/index.vue'),
                children: [
                    {
                        path: '/',
                        component: () => import('../views/customer-service/Service.vue')
                    },
                    {
                        path: 'service',
                        component: () => import('../views/customer-service/Recruitment.vue')
                    }
                ]
            }
        ]
    },
    {
        path: '/userCenter',
        name: 'UserCenter',
        component: () => import('../views/user-center/index.vue')
    },
    {
        path: '/personalInfo',
        name: 'PersonalInfo',
        component: () => import('../views/user-center/PersonalInfo.vue')
    },
    {
        path: '/myOrders',
        name: 'MyOrders',
        component: () => import('../views/user-center/MyOrders.vue')
    },
    {
        path: '/myCourses',
        name: 'MyCourses',
        component: () => import('../views/user-center/MyCourses.vue')
    },
    {
        path: '/myCreation',
        name: 'MyCreation',
        component: () => import('../views/user-center/MyCreation.vue')
    },
    {
        path: '/courseManagement',
        name: 'CourseManagement',
        component: () => import('../views/user-center/CourseManagement.vue')
    },
    {
        path: '/coursewareManagement',
        name: 'CoursewareManagement',
        component: () => import('../views/user-center/CoursewareManagement.vue')
    },
    {
        path: '/StudentManagement',
        name: 'StudentManagement',
        component: () => import('../views/user-center/StudentManagement.vue')
    },
    {
        path: '/studentOrders',
        name: 'StudentOrders',
        component: () => import('../views/user-center/StudentOrders.vue')
    },
    {
        path: '/education',
        name: 'education',
        component: () => import('../views/education/index.vue')
    },
    {
        path: '/researchBrigade',
        name: 'ResearchBrigade',
        component: () => import('../views/education/ResearchBrigade.vue'),
        children: [
            {
                path: '',
                component: () => import('../views/education/Research.vue')
            },
            {
                path: 'researchInformation',
                name: 'ResearchInformation',
                component: () => import('../views/education/ResearchInformation.vue')
            }
        ]
    },
    {
        path: '/online',
        name: 'Online',
        component: () => import('../views/education/OnlineContainer.vue'),
        children: [
            {
                path: '',
                component: () => import('../views/education/Online.vue'),
            }, {
                path: 'list/:parentId/:type?',
                name: 'OnlineList',
                component: () => import('../views/education/OnlineList.vue'),
            }
        ]
    },
    {
        path: '/courseList/:id',
        name: 'CourseList',
        component: () => import('../views/education/CourseList.vue')
    },
    {
        path: '/courseDetail/:courseId',
        name: 'CourseDetail',
        component: () => import('../views/education/CourseDetail.vue')
    },
    {
        path: '/activity',
        name: 'Activity',
        component: () => import('../views/education/Activity.vue')
    },
    {
        path: '/activityDetail/:id',
        name: 'ActivityDetail',
        component: () => import('../views/education/ActivityDetail.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        // if (((from.name === 'AboutSubpage' && to.name === 'AboutSubpage') || (from.name === 'InvestorRelationsSubpage' && to.name === 'InvestorRelationsSubpage')) && savedPosition) {
        //   return { x: 0, y: savedPosition.y }
        // } else {
        //   return { x: 0, y: 0 }
        // }

        if (savedPosition) {
            return savedPosition
        } else {
            if (from.meta.keepAlive) {
                if (document.documentElement && document.documentElement.scrollTop) {
                    from.meta.savedPosition = document.documentElement.scrollTop;
                } else if (document.body) {
                    from.meta.savedPosition = document.body.scrollTop;
                }
            }
            return {x: 0, y: to.meta.savedPosition || 0}
        }
    },
    // base: process.env.BASE_URL,
    base: '/',
    routes
})

router.beforeEach(async (to, from, next) => {
    // console.log(to);

    // 设置 seo 开始
    let flagEdu = to.name === undefined ? to.path : to.name;
    let webSite = 1;
    switch (flagEdu) {
        case 'education':
        case 'OnlineList':
        case 'ActivityDetail':
        case '/online':
            webSite = 2;
            break;
    }
    let sessionCacheSeoKey = `seoSettings${webSite}`;
    let seoSettings = sessionStorage.getItem(sessionCacheSeoKey);
    if (!seoSettings) {
        seoSettings = JSON.stringify(await loadSeoSettinss(webSite));
        console.log(seoSettings);
        sessionStorage.setItem(sessionCacheSeoKey, seoSettings);
    }
    seoSettings = JSON.parse(seoSettings);
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', `${seoSettings.cnKeyword},${seoSettings.enKeyword}`);
    document.querySelector('meta[name="description"]').setAttribute('content', `${seoSettings.cnDescription},${seoSettings.enDescription}`);
    head[0].appendChild(meta);
    // 设置 seo 结束

    next();
});

// 加载 seo 设置
async function loadSeoSettinss(webSite) {
    return new Promise(((resolve, reject) => {
        http({
            url: `backServer/front/common/seo`,
            method: 'get',
            headers: {'Web-Site': webSite}
        }).then((res) => {
            if (res.data.status === 200) {
                resolve(res.data.data);
            } else {
                reject();
            }
        });
    }));
}

export default router
