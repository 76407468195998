import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
    count: 0,
    lang: 'zh',
    username: '',
    refreshTemp: false
}

const mutations = {
    increment(state) {
        state.count++
    },
    decrement(state) {
        state.count--
    },
    changeLang(state) {
        state.lang === 'zh' ? state.lang = 'en' : state.lang = 'zh';
    },
    saveUsername(state, username) {
        state.username = username
    },
    changeRefreshStatus(state, isRefresh) {
        state.refreshTemp = isRefresh;
    }
}

const actions = {
    changeLang: (context) => {
        context.commit('changeLang');
    },
    changeRefreshStatus: (context) => {
        context.commit('changeRefreshStatus');
    },
    saveUsername: ({ commit }) => commit('saveUsername'),
    increment: ({ commit }) => commit('increment'),
    decrement: ({ commit }) => commit('decrement'),
    incrementIfOdd ({ commit, state }) {
        if ((state.count +1) % 2 === 0) {
            commit('increment')
        }
    },
    incrementAsync({ commit }) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                commit('increment')
                resolve()
            }, 1000)
        })
    }
}

const getters = {
    evenOrOdd: state => state.count % 2 === 0 ? 'even' : 'odd'
}

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})