const baseurl = 'http://192.168.10.33:18000/ssoServer/';
const prodStaticPath = 'http://edu.banbao.com/api/backServer/resources/';
// const devStaticPath = 'http://192.168.10.33:18801/resources/';
const devStaticPath = process.env.VUE_APP_RESOURCE_HOST;
const eduStaticPath = process.env.NODE_ENV === 'production' ? 'http://edu.banbao.com/api/userServer/resources/' : 'http://192.168.10.33:18802/resources/';
const eduCoverPath = process.env.NODE_ENV === 'production' ? 'http://edu.banbao.com/api/userServer/resources/cover/' : 'http://192.168.10.33:18802/resources/cover/';
const staticPath = process.env.NODE_ENV === 'production' ? prodStaticPath : devStaticPath;
const eduPlatformUrl = process.env.NODE_ENV === 'production' ? 'https://edu.banbao.com:63398' : 'http://192.168.10.33:8601';
const eduAI7PlatformUrl = process.env.NODE_ENV === 'production' ? 'https://edu.banbao.com:60398' : 'http://192.168.10.33:8601';
const banbaoUrl = process.env.NODE_ENV === 'production' ? 'banbao.com' : 'http://192.168.10.33'; // 用于保存到cookie
// const staticPath = 'http://120.241.104.176:18000/backServer/resources/';
// const staticPath = 'http://192.168.10.33:18801/resources/';
const urls = {
    login: 'ssoServer/security/tokenFront',
    register: 'ssoServer/register',
    imgCode: 'ssoServer/security/imgcode/',
    phoneCode: 'ssoServer/security/phoneCode',
    findPwd: 'ssoServer/security/findPwd',
    logout: 'ssoServer/security/clear',

    getSeoContent: 'backServer/front/common/seo',

    fileHeadUpload: 'userServer/file/head/upload',
    qualificationUpload: 'userServer/file/zz/upload',
    getPersonInfo: 'userServer/memberFront/find/',
    teacherApply: 'userServer/memberFront/apply',
    edit: 'userServer/memberFront/edit',
    getList: 'userServer/memberFront/list',
    importExcel: 'userServer/memberFront/import',
    getopusList: 'userServer/opusFront/opusList',
    coursewareSave: 'userServer/coursewareFront/save',
    coursewareEdit: 'userServer/coursewareFront/edit',
    coursewareFind: 'userServer/coursewareFront/find/',
    coursewareList: 'userServer/coursewareFront/list',
    coursewarePreviewText: 'userServer/file/courseware/previewText/',
    coursewarePreviewVideo: 'userServer/file/courseware/previewVideo/',
    fileCoursewareUpload: 'userServer/file/courseware/upload',

    coursewareBind: 'userServer/courseFront/coursewareBind',
    coursewareBindList: 'userServer/courseFront/coursewareBindList',
    courseEdit: 'userServer/courseFront/edit',
    courseFind: 'userServer/courseFront/find/',
    courseList: 'userServer/courseFront/list',
    courseSave: 'userServer/courseFront/save',
    addStudentAccount: 'userServer/memberFront/save',
    editStudentAccount: 'userServer/memberFront/editAccount',
    getStudentList: 'userServer/memberFront/list',
    importStudentList: 'userServer/memberFront/import',
    downloadModule: 'userServer/memberFront/template',
    unbind: 'userServer/courseFront/coursewareUnbind/',
    findCourseware: 'userServer/courseFront/findCourseware',
    findStudent: 'userServer/courseFront/findStudent',
    getOrderList: 'userServer/orderFront/list',

    getTemplates: "backServer/front/template/index/",
    getBannerList: "backServer/front/common/banner/",
    getTopbarNavs: "backServer/front/common/navigation/top",
    getAboutTemplate: "backServer/front/template/about/information/",
    getSubNavs: 'backServer/front/common/navigation/child/detail/',

    getSubpageList: 'backServer/front/common/article/page', // 获取子页列表
    getNavigations: "front/common/navigation/", // 顶部导航
    getBreadcrumb: 'backServer/front/common/navigation/path/', // 面包屑导航
    getContent: 'backServer/front/common/article/findLast/', // 获取子页内容

    getNewsTemp: "backServer/front/template/news/",
    getSiblingsArticle: "backServer/front/common/article/",
    getArticleDetail: "backServer/front/common/article/find/",

    /*** 产品中心 ***/
    getProductTemp: "backServer/front/template/product/",
    getProductList: "backServer/front/common/product/page",
    findProduct: "backServer/front/common/product/find/",
    getNavChild: "backServer/front/common/navigation/child/",
    productMould: "backServer/front/template/product/mould/",

    /*** 投资者关系 ***/
    getInvestorTemplate: "backServer/front/template/investorRelations/index/",

    /*** 创建留言 ***/
    createMessage: "backServer/front/common/feedback/save",
    /*** 研学旅 ***/
    getResearchTemp: "backServer/front/template/yxl/index/",
    research: "backServer/front/common/yxl/search",
    // getStockData: "backServer/front/common/sinajs",
    getStockData: "backServer/front/common/shares",
    /*** 客户服务 ***/
    getCustomerTemp: "backServer/front/template/customer/",

    /* 个人中心 */
    userServer: {
        memberFrontFind: `userServer/memberFront/find`,
        fileHeadUpload: `userServer/file/head/upload`,
        fileZzUpload: `userServer/file/zz/upload`,

        courseFrontList: `userServer/courseFront/list`,
        courseFrontSave: `userServer/courseFront/save`,
        courseFrontFind: `userServer/courseFront/find`,
        courseFrontEdit: `userServer/courseFront/edit`,
        courseFrontFindCourseware: `userServer/courseFront/findCourseware`,
        courseFrontCoursewareBindList: `userServer/courseFront/coursewareBindList`,
        fileCoverUpload: `userServer/file/cover/upload`,
        courseFrontMyCourse: `userServer/courseFront/myCourse`,
        courseFrontDel: `userServer/courseFront/del`,
        coursewareFrontDel: `userServer/coursewareFront/del`,
        coursewarePreviewFrontText: `userServer/file/courseware/previewFrontText`,
        coursewarePreviewFrontVideo: `userServer/file/courseware/previewFrontVideo`,

        memberFrontList: `userServer/memberFront/list`,
        memberFrontSave: `userServer/memberFront/save`,
        memberFrontEditAccount: `userServer/memberFront/editAccount`,
        memberFrontTemplate: `userServer/memberFront/template`,
        memberFrontImport: `userServer/memberFront/import`,

        orderFrontList: `userServer/orderFront/list`,
        opusFrontList: `userServer/opusFront/list`,
        opusFrontUpdateOpusName: `userServer/opusFront/updateOpusName`,
        opusFrontPublish: `userServer/opusFront/publish`,
        opusFrontDel: `userServer/opusFront/del`,
    },

    /*** 教育站 ***/
    getEducationTemp: 'backServer/front/template/education/index/', // 教育站首页模板
    saveComment: "userServer/comment/save", // 添加留言
    getStudentWorks: "userServer/online/opus", // 学生作品列表
    getCourseList: "userServer/online/course/list", // 课程列表
    getTeacherInfo: "userServer/online/course/teacher/", // 课程详情-讲师
    getCourseInfo: "userServer/online/course/detail/", // 课程信息
    getCoursewareList: "userServer/online/course/courseware", // 课程详情-课件
    getCourseComment: "userServer/online/course/comment", // 课程详情-评论
    previewVideo: "userServer/file/courseware/previewFrontVideo/", // 预览视频流
    courseDownload: "userServer/file/courseware/previewFrontText/", // 课件下载
    getCourseViews: "userServer/online/course/views/", // 访问量
    checkCourseIsBuyed: "userServer/online/course/check/", // 查看课程是否已购买
    getEducationProduct: "backServer/front/template/education/tools/", //
    getTeacherLevel: "userServer/levels/list", // 获取教师等级
    studentOpusUpload: 'userServer/file/opus/upload', // 学生作品-上传视频
    getOpusResources: 'userServer/resources/opus/', // 学生作品-视频预览

}

module.exports = {
    baseurl,
    staticPath,
    eduStaticPath,
    eduCoverPath,
    eduPlatformUrl,
    eduAI7PlatformUrl,
    urls,
    banbaoUrl
}