<template>
<!--  <v-app>-->
<!--    <v-main>-->
<!--    </v-main>-->
<!--  </v-app>-->
  <div>
    <router-view>
      <Home/>
    </router-view>

    <a v-show = "btnFlag" @click = "toTop" class = "topBtn" href="javascript:void(0);"><img :src = "require('@/assets/images/phone/icon_top.png')"></a>
  </div>
</template>

<script>
import Home from './views/Home.vue';

export default {
  name: 'App',

  components: {
    Home,
  },
  data() {
      return {
          scrollTop: 0,
          btnFlag:false
      }
  },
  methods:{
      toTop(){
          const that = this;
          let timer = setInterval(() => {
              let ispeed = Math.floor(-that.scrollTop / 5)
              document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
              if (that.scrollTop === 0) {
                clearInterval(timer)
              }
          }, 16);
      },
      scrollToTop () {
          const that = this
          let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
          that.scrollTop = scrollTop
          if (that.scrollTop > 60) {
            that.btnFlag = true
          } else {
            that.btnFlag = false
          }
      }

  },
  mounted(){
    window.addEventListener('scroll', this.scrollToTop)
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollToTop)
  }
};
</script>

<style lang="less" scoped>
.topBtn{
  display: none;
}

@media screen and (max-width: 750px) {
  .topBtn{
      z-index:120;
      display: block;
      position: fixed;
      width: 38px;
      height: 38px;
      right: 10px;
      bottom: 18px;
      img{
          display: block;
          width: 100%;
          height: 100%;
      }
  }
}

  
</style>