import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import http from './lib/http';
import Momemt from 'moment';

import 'lib-flexible/flexible.js'
import vuetify from './plugins/vuetify'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import animated from 'animate.css'
import VueI18n from 'vue-i18n'
import {banbaoUrl} from './lib/common'
import Cookies from 'js-cookie';


Vue.prototype.$http = http;
Vue.prototype.$cookies = Cookies;
/*if (window.cookieStore) {
  window.cookieStore.addEventListener('change', function(e) {
    if (e.changed.length > 0 && e.changed[0].name === 'app.token') {
      setTimeout(() =>{
        location.reload()
      }, 1500)
    }
    if (e.deleted.length > 0 && e.deleted[0].name === 'app.token') {
      setTimeout(() =>{
        location.reload()
      }, 1500)
    }
  })
}*/
let oldToken = Cookies.get('app.token', {domain: banbaoUrl, path: ''});
setInterval(() => {
    let token = Cookies.get('app.token', {domain: banbaoUrl, path: ''});
    let roles = Cookies.get('app.roles', {domain: banbaoUrl, path: ''});
    let platform = Cookies.get('app.platform', {domain: banbaoUrl, path: ''});
    if (roles) sessionStorage.setItem('roles', roles);
    if (oldToken !== token) {
        if (platform && platform !== 'website') {
            console.log(router.history.current.name);
            if (router.history.current.name === 'Login') {
                Cookies.remove('app.platform', {domain: banbaoUrl, path: ''})
                token ? location.reload() : null;
            } else {
                Cookies.remove('app.platform', {domain: banbaoUrl, path: ''})
                router.push({
                    path: "/login"
                })
            }
        }
    }
}, 3000)

Vue.filter('formatDate', function(value) {
    return Momemt(value).format('YYYY-MM-DD')
})
Vue.filter('formatDateTime', function(value) {
    return Momemt(value).format('YYYY-MM-DD hh:mm:ss')
})

Vue.filter('formatYearMonth', function(value) {
    const date = Momemt(value).format('YYYY-MM-DD');
    return date.split('-')[0]  + '-' + date.split('-')[1];
})
Vue.filter('formatDay', function(value) {
    const date = Momemt(value).format('YYYY-MM-DD');
    return date.split('-')[2];
})

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(animated)
Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'zh',
    messages: {
        'zh': require('../src/assets/languages/zh.js'),
        'en': require('../src/assets/languages/en.js')
    }
})

// 挂载 man
import man from "./plugins/man";
Vue.prototype.man = man;

new Vue({
    router,
    vuetify,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app')

